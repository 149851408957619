<template>
  <v-card>
    <s-toolbar
      dark
      color="#BDBDBD"
      label="Listado de Pallets por Abastecer"
    ></s-toolbar>
    <v-row>
      <div class="col-md-12 col-sm-12">

        <v-card dense>
          <v-row style="margin: auto">
            <v-col cols="12">
              <v-card flat>
                <v-row>
                  <v-col align="center" cols="12" lg="12" md="12">
                    <drag-drop
                      :dropzones="itemsPositionAll"
                      :originalData="dataPrihuela"
                      :dropzonesTitle="'PALLETS POR ABASTECER'"
                      :inPlace="true"
                      :hidden="false"
                    >
                      <template #dd-card="{ cardData }">
                        <card-wait-supply
                          :data="cardData"
                          @viewMovimients="viewMovimients($event)"
                          @viewSendProccess="viewSendProccess($event)"
                        />
                      </template>
                    </drag-drop>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-row>
  </v-card>
</template>

<script>
import _sMaturationTunnelPalletService from "@/services/FrozenProduction/MaturationTunnelPalletService";
// import FrzCustomCardParihuelaTunnel from "./FrzCustomCardParihuelaTunnel.vue";

// import FrzPalletMovimients from "./FrzPalletMovimients.vue";
import _sPalletProcessService from "@/services/FrozenProduction/PalletProcessService.js";
import SselectClientSap from "@/components/Sale/SselectClientSap.vue";



import CardWaitSupply from "./CardWaitSupply.vue";
import _sDesinfectedPalletService from "@/services/FrozenProduction/DisinfectedPalletService.js";

export default {
  props: {
    dataTunnel: null,
    // dataPrihuela: null,
  },

  components: {
    // FrzCustomCardParihuelaTunnel,
    // FrzPalletMovimients,
    CardWaitSupply,
    SselectClientSap,
  },

  data() {
    return {
      itemsPositionAll: [],
      alert: true,
      itemMovimients: [],
      openModalMovimients: false,
      closeDetail : false,
      palletProcess: {},
      viewModalSendProccess: false,
      dataPrihuela : []
    };
  },

  methods: {


    getData(){
      _sDesinfectedPalletService.list(this.$fun.getUserID())
      .then((r)=>{
        console.log('datos obtenidos',r.data);
        this.dataPrihuela = r.data;
        if(this.dataPrihuela.length > 0){
          this.generatedPositions();
        }else{
          for (var i = 0; i < 100 ; i++) {
            var objrrr = { id: i + 1, name: "Posición 0" + (i + 1), children: [] };
            this.itemsPositionAll.push(objrrr);
          }
        }
      })
    },
    

    generatedPositions() {
      for (var i = 0; i < 100 ; i++) {
        var objrrr = { id: i + 1, name: "Posición 0" + (i + 1), children: [] };
        this.itemsPositionAll.push(objrrr);
      }

      this.itemsPositionAll.forEach((element) => {
        element.children = [];
        var obkkk = this.dataPrihuela.find((x) => x.PprPosition == element.id);  // color el numero de registros

        if (obkkk !== undefined) {
          element.children.push(obkkk);

          this.dataPrihuela = this.dataPrihuela.filter(
            (x) => x.PprID != obkkk.PprID
          );
        }
      });

      console.log('posiciones',this.itemsPositionAll)
    },


     

    // closeDetail() {
    //   this.$emit("closeDetail");
    // },
  },

  created() {

    this.getData();
    // this.generatedPositions();
  },
};
</script>